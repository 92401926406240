export const formFieldsConfig = {
    "Utility Bill": [
      {
        name: 'utilityBill',
        label: 'Utility Bill',
        type: 'imageUpload',
      },
    ],
    "Tier 2 Insurance": [
      {
        name: 'tier2Insurance',
        label: 'Tier 2 Insurance',
        type: 'dropdown',
        options: [
            'YES'
        ],
      },
      { name: 'declarationPage', label: 'Declaration Page', type: 'imageUpload' },
    ],
    Adders: [
      {
        name: 'moduleAdders',
        label: 'Module Adders',
        type: 'dropdown',
        options: [
          'QCELL 400 G10+ USA MADE',
          'QCELL 405 G10+ USA MADE NEW ENGLAND ONLY',
          'REC Alpha 405 (PREMIUM MODULE)',
          'Hyperion 400W',
          'Maxeon 410'
        ],
      },
      {
        name: 'battery',
        label: 'Battery',
        type: 'dropdown',
        options: [
          'FranklinWH',
          'Enphase 5P',
          'Tesla Powerwall 2'
        ],
      },
      {
        name: 'batteryQuantity',
        label: 'Battery Quantity',
        type: 'textField',
        fieldType: 'number',
      },
      {
        name: 'roofAdders',
        label: 'Roof Adders',
        type: 'dropdown',
        options: [
            'Metal Roof',
            'Non Standard Roof Types',
            'Ballasted Racking'
        ],
      },
      {
        name: 'mountingAdders',
        label: 'Mounting Adders',
        type: 'dropdown',
        options: [
            'Steep Roof',
            'High Roof 3 or more Stories'
        ],
      },
      {
        name: 'sunnovaBatteryWarranty',
        label: 'Sunnova Battery Warranty',
        type: 'dropdown',
        options: [
            'YES'
        ],
      },
      {
        name: 'batteryInsideGarage',
        label: 'Battery Inside Garage',
        type: 'dropdown',
        options: [
            'YES'
        ],
      },
      {
        name: 'electricalAdders',
        label: 'Electrical Adders',
        type: 'dropdown',
        options: [
            'MPU FL',
            'MPU New England (MA RI CT)',
            'Meter Main Combination upgrade'
        ],
      },
      {
        name: 'structuralReinforcement',
        label: 'Structural Reinforcement',
        type: 'dropdown',
        options: [
            'YES'
        ],
      },
      {
        name: 'evCharger',
        label: 'EV Charger',
        type: 'dropdown',
        options: [
            'Chargepoint Flex Home EV Charger 50A Max',
            'Tesla Universal Charger',
            'Wallbox'
        ],
      },
      {
        name: 'evChargerQuantity',
        label: 'EV Charger Quantity',
        type: 'textField',
        fieldType: 'number',
      },
      {
        name: 'groundMount',
        label: 'Ground Mount',
        type: 'dropdown',
        options: [
            'YES'
        ],
      },
      {
        name: 'solarInsure',
        label: 'Solar Insure',
        type: 'dropdown',
        options: ['PV', 'Battery', 'PV + Battery'],
      },
      
    ],
    "Site Survey": [
        { name: 'droneOr360WalkAround', label: 'Drone or 360 Walk Around', type: 'imageUpload' },
        { name: 'frontOfHome', label: 'Front of Home', type: 'imageUpload' },
        { name: 'rightSideOfHome', label: 'Right Side of Home', type: 'imageUpload' },
        { name: 'leftSideOfHome', label: 'Left Side of Home', type: 'imageUpload' },
        { name: 'backOfHome', label: 'Back of Home', type: 'imageUpload' },
        { name: 'treesToBeRemoved', label: 'Trees To Be Removed', type: 'imageUpload' },
        { name: 'utilityMeter', label: 'Utility Meter', type: 'imageUpload' },
        { name: 'atticRafters', label: 'Attic Rafters', type: 'imageUpload' },
        {
          name: 'rafterSpacing',
          label: 'Rafter Spacing',
          type: 'dropdown',
          options: [
              '12',
              '16',
              '24'
          ],
        },
        {
          name: 'rafterSize',
          label: 'Rafter Size',
          type: 'dropdown',
          options: [
              '2 x 4',
              '2 x 6',
              '2 x 8'
          ],
        },
        { name: 'roof', label: 'Roof', type: 'imageUpload' },
        { name: 'mainServicePanelDoorClosed', label: 'Main Service Panel Door Closed', type: 'imageUpload' },
        { name: 'mainServicePanelDoorOpen', label: 'Main Service Panel Door Open', type: 'imageUpload' },
        { name: 'mainServicePanelBreakers', label: 'Main Service Panel Breakers', type: 'imageUpload' },
        { name: 'mainServicePanelDiagram', label: 'Main Service Panel Diagram', type: 'imageUpload' },
    ],
  };


  


